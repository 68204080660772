<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="600px">
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">Öğrenci Ekle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="12" md="6" name="name">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="İsim"
                  v-model="form.name"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="surname">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Soyadı"
                  v-model="form.surname"
                  @blur="$v.form.surname.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="gender">
                <v-select
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Cinsiyet"
                  v-model="form.gender"
                  @blur="$v.form.gender.$touch()"
                  :items="genders"
                ></v-select>
              </form-group>
              <form-group cols="12" md="6" name="educationLevel">
                <v-select
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Eğitim Seviyesi"
                  v-model="form.educationLevel"
                  @blur="$v.form.educationLevel.$touch()"
                  :items="educationLevels"
                ></v-select>
              </form-group>
              <form-group cols="12" md="6" name="graduatedFrom">
                <v-select
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Mezun Olduğu Sınıf"
                  v-model="form.graduatedFrom"
                  @blur="$v.form.graduatedFrom.$touch()"
                  :items="classes"
                ></v-select>
              </form-group>
              <form-group cols="12" md="6" name="startDate">
                <template slot-scope="{ attrs }">
                  <v-menu
                    ref="startDate"
                    v-model="startDate"
                    :close-on-content-click="false"
                    :return-value.sync="form.startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-bind="attrs"
                        v-model="form.startDate"
                        label="Kursa Başlama Tarihi"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.startDate"
                      locale="tr"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startDate = false"
                        >İptal</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startDate.save(form.startDate)"
                        >Bitti</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </template>
              </form-group>
              <form-group cols="12" md="6" name="birthDate">
                <template slot-scope="{ attrs }">
                  <v-menu
                    ref="birthDate"
                    v-model="birthDate"
                    :close-on-content-click="false"
                    :return-value.sync="form.birthDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.birthDate"
                        label="Doğum Tarihi"
                        v-bind="attrs"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.birthDate"
                      locale="tr"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="birthDate = false"
                        >İptal</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.birthDate.save(form.birthDate)"
                        >Bitti</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </template>
              </form-group>
              <form-group cols="12" md="6" name="disabilityStatus">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Engel Durumu"
                  v-model="form.disabilityStatus"
                ></v-text-field>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  props: ['visible', 'course'],

  mixins: [validationMixin],

  validations: {
    form: {
      name: { required },
      surname: { required },
      graduatedFrom: {},
      gender: {},
      educationLevel: {},
      startDate: {},
      birthDate: {}
    }
  },

  data() {
    return {
      loading: true,
      startDate: false,
      birthDate: false,
      form: {
        courseId: this.course.id,
        disabilityStatus: 'Sağlıklı'
      },
      educationLevels: ['İlkokul', 'Ortaokul', 'Lise', 'Mezun'],
      genders: ['Erkek', 'Kadın'],
      classes: [
        { text: '1. Sınıf', value: '1' },
        { text: '2. Sınıf', value: '2' },
        { text: '3. Sınıf', value: '3' },
        { text: '4. Sınıf', value: '4' }
      ]
    };
  },

  methods: {
    async submit() {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          await this.axios.post(this.namespace + '/students', {
            ...this.form
          });
          this.close();
          this.$emit('done');
          this.$store.dispatch('setToast', {
            text: 'Başarılı bir şekilde eklendi!',
            color: 'success'
          });
        }
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Güncelleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
      this.$v.$reset();
      this.form = {
        courseId: this.course.id,
        disabilityStatus: 'Sağlıklı'
      };
    }
  }
};
</script>
