<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <card
    :title="missionGroup.title"
    max-height="800"
    head-class="drag-handle"
    body-class="pr-2 pt-1 pb-1"
  >
    <v-menu slot="toolbar" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn color="primary" outlined dark v-on="on">
          İşlemler
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="addMissionModal = true">
          Görev Ekle
        </v-list-item>
        <v-list-item @click="editModal = true">
          Düzenle
        </v-list-item>
        <v-list-item @click="confirmDeleteModal = true">
          Sil
        </v-list-item>
      </v-list>
    </v-menu>

    <draggable
      v-model="missions"
      @start="drag = true"
      @end="drag = false"
      handle=".handle"
      ghost-class="ghost"
    >
      <div
        v-for="item in missions"
        style="margin-left: -20px"
        class="d-flex align-items-center bg-white mb-3 p-3"
        :key="item.id"
      >
        <span
          class="btn btn-xs btn-light-primary ml-1 mr-3 handle font-weight-bold"
          style="padding: 0 5px"
        >
          <v-icon class="p-0">drag_handle</v-icon>
        </span>
        <div class="d-flex flex-column flex-grow-1 font-weight-bold">
          <div>
            {{ item.title }}
            <span class="text-muted "> ({{ item.point }} Puan) </span>
          </div>

          <span class="text-muted font-weight-bold">
            {{ item.description }}
          </span>
        </div>

        <MissionUpdate :mission="item" @done="getMissionList"></MissionUpdate>
        <span
          class="btn btn-sm btn-link-danger font-weight-bold"
          @click="
            confirmModal = true;
            deleteId = item.id;
          "
        >
          <v-icon class="p-0">delete</v-icon>
        </span>
      </div>
    </draggable>

    <v-dialog v-model="confirmDeleteModal" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Uyarı</span>
        </v-card-title>

        <v-card-text>
          Silmek istediğinize emin misiniz? Bu gruba ait bütün görevler ve bu
          görevleri yerine geriren öğrencilerin bilgileri silinecektir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDeleteModal = false">
            İptal
          </v-btn>
          <v-btn color="blue darken-1" @click="removeGroup" text>
            SİL
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmModal" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Uyarı</span>
        </v-card-title>

        <v-card-text>
          Silmek istediğinize emin misiniz?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmModal = false">
            İptal
          </v-btn>
          <v-btn color="blue darken-1" @click="remove" text>
            SİL
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MissionGroupUpdate
      :missionGroup="missionGroup"
      :visible="editModal"
      @close="editModal = false"
    ></MissionGroupUpdate>
    <MissionAdd
      :missionGroup="missionGroup"
      :visible="addMissionModal"
      @close="addMissionModal = false"
      @done="getMissionList"
    ></MissionAdd>
  </card>
</template>

<script>
import MissionAdd from './MissionAdd';
import MissionUpdate from './MissionUpdate';
import MissionGroupUpdate from './MissionGroupUpdate';
import draggable from 'vuedraggable';

export default {
  props: ['missionGroup'],
  components: { MissionAdd, MissionGroupUpdate, MissionUpdate, draggable },
  data() {
    return {
      missions: [],
      drag: false,
      loading: true,
      deleteId: null,
      confirmModal: false,
      confirmDeleteModal: false,
      editModal: false,
      addMissionModal: false
    };
  },
  mounted() {
    this.getMissionList();
  },
  methods: {
    async getMissionList() {
      try {
        const { data } = await this.axios.get(
          this.namespace + `/missionGroups/${this.missionGroup.id}/missions`
        );

        this.missions = data;

        setTimeout(() => {
          this.loading = false;
        }, 200);
      } catch (e) {
        console.log(e);
      }
    },
    async remove() {
      try {
        await this.axios.delete(this.namespace + `/missions/${this.deleteId}`);
        this.getMissionList();
        this.confirmModal = false;

        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde silindi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          visible: true,
          text: 'İşlem esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    async removeGroup() {
      try {
        await this.axios.delete(
          this.namespace + `/missionGroups/${this.missionGroup.id}`
        );
        this.confirmDeleteModal = false;
        this.$emit('delete');

        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde silindi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          visible: true,
          text: 'İşlem esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    }
  },
  watch: {
    async missions(list) {
      try {
        if (!this.loading) {
          await this.axios.patch(this.namespace + '/missions/order', {
            list
          });
          this.$store.dispatch('setToast', {
            text: 'Başarılı bir şekilde güncellendi!',
            color: 'success'
          });
        }
      } catch (e) {
        this.$store.dispatch('setToast', {
          visible: true,
          text: 'İşlem esnasında hata meydana geldi!',
          color: 'danger'
        });
      }
    }
  }
};
</script>

<style>
.drag-handle,
.handle {
  cursor: move !important;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb !important;
}
</style>
