<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <card :title="title">
      <v-menu slot="toolbar" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" outlined dark v-on="on">
            İşlemler
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="studentAddModal = true">
            Yeni Ekle
          </v-list-item>
          <v-list-item @click="$refs.fileInput.click()">
            İçe aktar
          </v-list-item>
        </v-list>
      </v-menu>
      <input
        @change="uploadFile"
        type="file"
        accept=".xls"
        ref="fileInput"
        style="display: none"
      />

      <v-data-table
        :headers="headers"
        :items="students"
        :loading="loading"
        :items-per-page="5"
      >
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{ name: routeName, params: { studentId: item.id } }"
          >
            {{ item.name }} {{ item.surname }}
          </router-link>
        </template>
        <template v-slot:item.birthDate="{ item }">
          {{ moment(item.birthDate).format('D MMMM YYYY') }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="$refs.studentUpdateModal.open(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="
              deletePromt = true;
              deleteId = item.id;
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </card>
    <DeletePromt
      :visible="deletePromt"
      @close="deletePromt = false"
      @done="deleteStudent()"
    ></DeletePromt>
    <StudentUpdate ref="studentUpdateModal" @done="getStudentList"></StudentUpdate>
    <StudentAdd
      :course="course"
      :visible="studentAddModal"
      @close="studentAddModal = false"
      @done="getStudentList()"
    ></StudentAdd>
  </div>
</template>

<script>
import StudentAdd from './StudentAdd';
import moment from 'moment';
import 'moment/locale/tr';
import DeletePromt from '@/view/content/components/DeletePromt';
import StudentUpdate from '@/view/pages/admin/student/StudentUpdate';

export default {
  props: ['course'],
  components: { StudentAdd, DeletePromt, StudentUpdate },
  data() {
    return {
      title: 'Öğrenci Listesi',
      headers: [
        { text: 'Ad Soyad', value: 'name' },
        { text: 'Cinsiyet', value: 'gender' },
        { text: 'Kayıt Kodu', value: 'courseRegisterCode' },
        { text: 'Doğum Tarihi', value: 'birthDate' },
        { text: 'İşlemler', value: 'actions' }
      ],
      students: [],
      studentAddModal: false,
      deletePromt: false,
      deleteId: null,
      loading: true
    };
  },
  mounted() {
    this.getStudentList();
  },
  methods: {
    async getStudentList() {
      try {
        const { data } = await this.axios.get(
          this.namespace + `/courses/${this.course.id}/students`
        );

        this.students = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async deleteStudent() {
      try {
        await this.axios.delete(this.namespace + '/students/' + this.deleteId);

        this.getStudentList();
        this.deletePromt = false;
        this.deleteId = null;
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde silindi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Silme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    async uploadFile(e) {
      try {
        const formData = new FormData();
        formData.append('list', e.target.files[0]);

        await this.axios.put(
          `${this.namespace}/courses/${this.course.id}/studentInsert`,
          formData,
          {
            headers: {
              accept: 'application/json',
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            }
          }
        );
        this.$refs.fileInput.value = '';
        this.getStudentList();
        this.$store.dispatch('setToast', {
          text: 'Öğrenciler başarılı bir şekilde içe aktarıldı!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'İçe aktarma esnasında hata meydana geldi!',
          color: 'danger'
        });
      }
    },
    moment
  },
  computed: {
    routeName() {
      return this.namespace === 'panel' ? 'panel-student' : 'student';
    }
  }
};
</script>
