import draggable from "vuedraggable";
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-row>
      <b-col xs="12">
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            Kurs bilgilerini düzenleyebilirsiniz. Kursa öğrenci ekleyebilir aynı
            zamanda excel dosyasından içeri aktarabilirsiniz.
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="4">
        <card title="Kurs Bilgileri">
          <v-btn
            @click="$refs.courseEditModal.open(course)"
            slot="toolbar"
            color="primary"
            outlined
            dark
          >
            Düzenle
          </v-btn>

          <detail-list-item field="Kurs Adı" :value="course.name" />
          <detail-list-item
            field="Eğitmen Adı"
            :value="course.instructor.name + ' ' + course.instructor.surname"
          />
          <detail-list-item
            field="Başlama Tarihi"
            :value="moment(course.startDate).format('D MMMM YYYY')"
          />
          <detail-list-item
            field="Bitiş Tarihi"
            :value="moment(course.endDate).format('D MMMM YYYY')"
          />
        </card>
      </b-col>
      <b-col xs="12" sm="12" md="8">
        <Students :course="course"></Students>
      </b-col>
    </b-row>

    <b-row>
      <b-col xs="12" class="text-center">
        <div class="text-center">
          <h2 class="">
            Görev Listeleri
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="black" v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                Kursu ilk açıldığında örnek görev listeleri oluşturulur. Bu
                listeleri güncelleyebilir, kullanmadıklarınızı silebilir veya
                yeni listeler ekleyebilirsiniz.
              </span>
            </v-tooltip>
          </h2>
        </div>
      </b-col>
    </b-row>
    <b-row v-show="course.missionGroups.length === 0">
      <b-col xs="12">
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-text text-center">
            Kursa ait hiç görev listesi bulunmamaktadır. Bir tane oluşturmak
            için aşağıdaki butona tıklayabilirsiniz.
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <draggable
      v-model="course.missionGroups"
      @start="drag = true"
      @end="drag = false"
      handle=".drag-handle"
      ghost-class="ghost"
      class="row"
    >
      <b-col
        v-for="missionGroup in course.missionGroups"
        class="mission-group-item"
        :key="missionGroup.id"
        md="4"
      >
        <MissionList
          :missionGroup="missionGroup"
          @delete="getCourse()"
        ></MissionList>
      </b-col>
    </draggable>
    <b-row>
      <b-col xs="12" class="text-center">
        <v-btn @click="addMissionGroupModal = true" color="primary" outlined>
          YENİ GÖREV LİSTESİ EKLE
        </v-btn>
      </b-col>
    </b-row>
    <CourseUpdate ref="courseEditModal" />
    <MissionGroupAdd
      ref="missionAddModal"
      :course="course"
      :visible="addMissionGroupModal"
      @close="addMissionGroupModal = false"
      @done="getCourse()"
    ></MissionGroupAdd>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import DetailListItem from '@/view/content/components/DetailListItem.vue';
import CourseUpdate from './CourseUpdate';
import MissionGroupAdd from './MissionGroupAdd';
import Students from './students/Students';
import MissionList from './missions/MissionList';
import draggable from 'vuedraggable';

import moment from 'moment';
import 'moment/locale/tr';

export default {
  props: ['courseId'],
  components: {
    DetailListItem,
    MissionGroupAdd,
    CourseUpdate,
    Students,
    MissionList,
    draggable
  },

  data() {
    return {
      course: {
        id: this.courseId,
        instructor: {},
        missionGroups: []
      },
      loading: true,
      editModal: false,
      addMissionGroupModal: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Camiler' },
      { title: 'Hocalar' },
      { title: 'Kurslar' },
      { title: 'Kurs Detay' }
    ]);
    this.getCourse();
  },
  methods: {
    async getCourse() {
      try {
        const { data } = await this.axios.get(
          this.namespace + '/courses/' + this.courseId
        );

        this.course = data;

        setTimeout(() => {
          this.loading = false;
        }, 200);
      } catch (e) {
        console.log(e);
      }
    },
    moment
  },
  watch: {
    'course.missionGroups': {
      async handler(list) {
        try {
          if (!this.loading) {
            await this.axios.patch(this.namespace + '/missionGroups/order', {
              list
            });
            this.$store.dispatch('setToast', {
              text: 'Başarılı bir şekilde güncellendi!',
              color: 'success'
            });
          }
        } catch (e) {
          this.$store.dispatch('setToast', {
            visible: true,
            text: 'İşlem esnasında hata meydana geldi!',
            color: 'danger'
          });
        }
      }
    }
  }
};
</script>
