<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="600px">
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">Görev Listesi Ekle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="12" name="title">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Başlık"
                  v-model="form.title"
                  @blur="$v.form.title.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" name="description">
                <v-textarea
                  label="Açıklama"
                  v-model="form.description"
                ></v-textarea>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  props: ['visible', 'course'],
  mixins: [validationMixin],

  validations: {
    form: {
      name: { required }
    }
  },

  data() {
    return {
      loading: true,
      form: {}
    };
  },

  methods: {
    async submit() {
      try {
        await this.axios.post(this.namespace + '/missionGroups', {
          courseId: this.course.id,
          ...this.form
        });

        this.close();
        this.$emit('done');

        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde eklendi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Ekleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
      this.$v.$reset();
    }
  },

  watch: {
    visible: {
      handler(nv) {
        if (nv) {
          this.form = {};
        }
      }
    }
  }
};
</script>
