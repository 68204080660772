<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="600px">
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">{{ missionGroup.title }} Görev Ekle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="6" name="title">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Başlık"
                  v-model="form.title"
                  @blur="$v.form.title.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="6" name="point">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Puan"
                  v-model="form.point"
                  @blur="$v.form.point.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" name="description">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Açıklama"
                  v-model="form.description"
                  @blur="$v.form.description.$touch()"
                ></v-text-field>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, numeric } from 'vuelidate/lib/validators';

export default {
  props: ['visible', 'missionGroup'],

  mixins: [validationMixin],

  validations: {
    form: {
      title: { required },
      point: { required, numeric }
    }
  },

  data() {
    return {
      form: {
        missionGroupId: this.missionGroup.id
      }
    };
  },

  methods: {
    async submit() {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          await this.axios.post(this.namespace + '/missions', this.form);
          this.close();
          this.$emit('done');
          this.$store.dispatch('setToast', {
            text: 'Başarılı bir şekilde eklendi!',
            color: 'success'
          });
        }
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Güncelleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
      this.$v.$reset();
      this.form = {
        missionGroupId: this.missionGroup.id
      };
    }
  }
};
</script>
